import { UserService } from "@/services/"
import JwtHelper from "@/utils/jwtHelper";

const state = {
  authorized: null,
  authUser: null,
  isRefreshing: false,
  refreshingCall: undefined,
  user: null,
  permissions: []
}

export const getters = {
  isAuthorized: state => state.authorized,
  getCurrentUser: state => state.user,
  getCurrentUserPermissions: state => state.permissions
}

export const actions = {
  setCurrentUser: ({ commit, state }, user) => {
    return new Promise((resolve) => {
      let { id, firstName, lastName, email } = user

      commit('AUTH_SET_USER', { id, firstName, lastName, email });
      JwtHelper.setToken(JwtHelper.keys.user, { id, firstName, lastName, email, companies: state.user.companies });

      resolve();
    });
  },
  setRefreshingState: ({ commit }, isRefreshed) => {
    return new Promise((resolve) => {
      commit('AUTH_SET_REFRESHING_STATE', isRefreshed)
      resolve();
    });
  },
  setRefreshingCall: ({ commit }, refreshCall) => {
    return new Promise((resolve) => {
      commit('AUTH_SET_REFRESH_CALL', refreshCall)
      resolve();
    });
  },
  authorize: ({ commit }, model) => {
    return UserService.authenticate(model)
      .then(response => {
        let { id, firstName, lastName, email, companies, isClientAdmin, isSystemAdmin } = response.data
        let isAdmin = isClientAdmin || isSystemAdmin;

        commit('AUTH_SET_ISAUTH', true)
        commit('AUTH_SET_AUTH_USER', response.data)
        commit('AUTH_SET_USER', { id, firstName, lastName, email, companies, isAdmin })
        commit('AUTH_SET_PERMISSIONS', response.data)

        JwtHelper.setToken(JwtHelper.keys.jwt, response.data);
        JwtHelper.setToken(JwtHelper.keys.user, { id, firstName, lastName, email, companies, isAdmin });

        return new Promise((resolve) => {
          resolve()
        })
      })
      .catch(err => {
        commit('AUTH_SET_ISAUTH', false)
        throw err
      })
  },
  testAuth: ({ commit }, model) => {

    // console.log('test store', model);

    let { id, firstName, lastName, email, companies, isClientAdmin, isSystemAdmin } = model
    let isAdmin = isClientAdmin || isSystemAdmin;

    commit('AUTH_SET_ISAUTH', true)
    commit('AUTH_SET_AUTH_USER', model)
    commit('AUTH_SET_USER', { id, firstName, lastName, email, companies, isAdmin })
    commit('AUTH_SET_PERMISSIONS', model)

    JwtHelper.setToken(JwtHelper.keys.jwt, model);
    JwtHelper.setToken(JwtHelper.keys.user, { id, firstName, lastName, email, companies, isAdmin });

    return new Promise((resolve) => {
      resolve()
    })


  },
  refreshUser: ({ commit }, { token, user }) => {
    commit('AUTH_SET_ISAUTH', true)
    commit('AUTH_SET_AUTH_USER', token)
    commit('AUTH_SET_USER', user)
    commit('AUTH_SET_PERMISSIONS', token)

    JwtHelper.setToken(JwtHelper.keys.jwt, token);
    JwtHelper.setToken(JwtHelper.keys.user, user);

    return new Promise((resolve) => {
      resolve()
    })
  },
  logout: ({ commit }) => {
    commit('AUTH_SET_ISAUTH', false)
    commit('AUTH_SET_AUTH_USER', null)
    commit('AUTH_SET_USER', null)
    commit('AUTH_SET_PERMISSIONS', null)
    commit('GLOBAL_SET_NOTIFICATIONS_DISPLAY', false, { root: true });

    sessionStorage.clear();

    return new Promise((resolve) => {
      resolve();
    });
  }
}

export const mutations = {
  'AUTH_SET_REFRESHING_STATE'(state, isRefreshed) {
    state.isRefreshing = isRefreshed
  },
  'AUTH_SET_REFRESH_CALL'(state, refreshCall) {
    state.refreshingCall = refreshCall
  },
  'AUTH_SET_ISAUTH'(state, isAuthorized) {
    state.authorized = isAuthorized
  },
  'AUTH_SET_AUTH_USER'(state, user) {
    state.authUser = user
  },
  'AUTH_SET_USER'(state, user) {
    if (!user) {
      state.user = null;
      return;
    }

    state.user = { ...state.user, ...user };
  },
  'AUTH_SET_PERMISSIONS'(state, user) {
    let permissions = [];

    if (user && user.permissions) {
      permissions = permissions.concat( user.permissions.map(p => p.code) )
    }

    if (user && user.zonePermissions) {
      permissions = permissions.concat([...new Set( user.zonePermissions.map(i => i.permissions).reduce((acc, val) => acc.concat(val), []) )])
    }

    state.permissions = permissions;
  }
}

export default { state, getters, actions, mutations }
