import Vue from 'vue'
import Router from 'vue-router'
import JwtHelper from "@/utils/jwtHelper";

Vue.use(Router)

const dashboardRoutes = [
  { path: "/", redirect: "/dashboard" },
  { path: "/dashboard", meta: { requiresAuth: true }, component: () => import("@/views/dashboard/dashboard.vue") }
];

const projectRoutes = [
  { path: "/project", redirect: "/dashboard" },
  { path: "/project/search", name: 'project-search', meta: { requiresAuth: true }, props: true, component: () => import("@/views/project/search.vue") },
  { path: "/project/info", redirect: "/project/search" },
  { path: "/project/info/:id", name: 'project-info', meta: { requiresAuth: true }, props: true, component: () => import("@/views/project/info.vue") },
  { path: "/project/new", meta: { requiresAuth: ["add_project", "add_project_private"] }, component: () => import("@/views/project/create.vue") },
  { path: "/project/edit", redirect: "/project/new" },
  { path: "/project/edit/:id", meta: { requiresAuth: ["edit_project", "add_project_private"] }, component: () => import("@/views/project/create.vue") },
  { path: "/project/purchase/:id", name: 'project-purchase', meta: { requiresAuth: true }, props: true, component: () => import("@/views/project/purchase.vue") },
  { path: "/project/purchase/:id/success", name: 'project-purchase-success', meta: { requiresAuth: true }, props: true, component: () => import("@/views/project/purchaseSuccess.vue") },
  { path: "/project/purchase/:id/error", name: 'project-purchase-error', meta: { requiresAuth: true }, props: true, component: () => import("@/views/project/purchaseError.vue") },
];

const companyRoutes = [
  { path: "/company", redirect: "/dashboard" },
  { path: "/company/search", meta: { requiresAuth: ["view_company"] }, component: () => import("@/views/company/search.vue") },
  { path: "/company/new", meta: { requiresAuth: ["view_company", "add_company"] }, component: () => import("@/views/company/create.vue") },
  { path: "/company/edit", redirect: "/company/new" },
  { path: "/company/edit/:id", meta: { requiresAuth: ["view_company", "edit_company"] }, component: () => import("@/views/company/create.vue") }
];

const membershipRoutes = [
  { path: "/membership/upgrade", meta: { requiresAuth: true }, component: () => import("@/views/membership/upgrade.vue") }
];

const configurationRoutes = [
  { path: "/configuration", redirect: "/dashboard" },
  { path: "/configuration/advertisements", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/advertisements.vue") },
  { path: "/configuration/addressbook", meta: { requiresAuth: 'send_bid_invitations' }, component: () => import("@/views/configuration/address-books.vue") },
  { path: "/configuration/contact-types", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/contact-types.vue") },
  { path: "/configuration/news", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/news.vue") },
  { path: "/configuration/owners", meta: { requiresAuth: ["view_owner"] }, component: () => import("@/views/configuration/owners.vue") },
  { path: "/configuration/owner-types", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/owner-types.vue") },
  { path: "/configuration/participant-types", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/participant-types.vue") },
  { path: "/configuration/payments", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/payments.vue") },
  { path: "/configuration/procurement-types", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/procurement-types.vue") },
  { path: "/configuration/service-regions", meta: { requiresAuth: ["view_serviceRegion"] }, component: () => import("@/views/configuration/service-regions.vue") },
  { path: "/configuration/tender-stages", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/tender-stages.vue") },
  { path: "/configuration/trade-agreements", meta: { requiresAuth: 'admin' }, component: () => import("@/views/configuration/trade-agreements.vue") },
  { path: "/configuration/roles", meta: { requiresAuth: ["view_role"] }, component: () => import("@/views/configuration/roles.vue") },
  { path: "/configuration/users", meta: { requiresAuth: ["view_user"] }, component: () => import("@/views/configuration/users.vue") },
  { path: "/configuration/zones", meta: { requiresAuth: ["view_zone"] }, component: () => import("@/views/configuration/zones.vue") },
];

const accountRoutes = [
  { path: "/account", redirect: "/dashboard" },
  { path: "/account/settings", meta: { requiresAuth: true }, component: () => import("@/views/account/settings.vue") }
];

const loginRoutes = [
  {
    name: 'account', path: "/", component: () => import("@/views/layout/account.vue"),
    children: [
      { path: "/unsubscribe", component: () => import("@/views/account/unsubscribe.vue") },
      { path: "/login", component: () => import("@/views/account/login.vue") },
      { path: "/forgotpassword", name: "forgotpassword", component: () => import("@/views/account/forgot-password.vue") },
      { path: "/passwordreset/", redirect: "/login" },
      { path: "/passwordreset/:token", component: () => import("@/views/account/password-reset.vue") }
    ]
  },
  { path: "/logout", component: () => import("@/views/account/logout.vue") }
]

const publicRoutes = [
  {
    path: '/signup', component: () => import("@/views/public/signup.vue"),
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_TENANT_NAME.toLowerCase().includes('cinet')) {
        next({ path: '/login' })
      } else {
        next()
      }
    },
    children: [
      { name: 'signup-company-lookup', path: '/', component: () => import("@/views/public/company-lookup.vue") },
      { name: 'signup-company-join', path: 'join/:slug', component: () => import("@/views/public/company-join.vue") },
      { name: 'signup-company-new', path: 'create', component: () => import("@/views/public/company-create.vue") },
      { path: 'join/', redirect: "/signup" },
      { name: 'signup-company-success', path: 'success', component: () => import("@/views/public/company-create-success.vue") },
      { name: 'signup-company-error', path: 'error', component: () => import("@/views/public/company-create-error.vue") },
    ]
  },
]

const invite2BidRoutes = [
    {
        path: "/respond-to-invitation", component: () => import("@/views/layout/account.vue"),
        children: [
            { path: "/", meta: { requiresAuth: false }, component: () => import("@/views/project/components/invite-2-bid/respond-to-invitation.vue") }
        ]
    }
];

export const routes = [
  ...dashboardRoutes,
  ...projectRoutes,
  ...companyRoutes,
  ...membershipRoutes,
  ...configurationRoutes,
  ...accountRoutes,
  ...loginRoutes,
  ...publicRoutes,
  ...invite2BidRoutes,
  { path: "*", redirect: "/dashboard" }
];

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeResolve((to, from, next) => {
  let isValid = true;

  if (to.matched.some(record => !!record.meta.requiresAuth)) {
    let jwt = JwtHelper.getJwtToken();
    isValid = !!jwt;
  }

  if (to.meta && to.meta.requiresAuth && typeof to.meta.requiresAuth === 'string' && to.meta.requiresAuth === 'admin') {
    isValid = router.app.$permissions().isSysAdmin || router.app.$permissions().isClientAdmin;
  }
  else if (to.meta && to.meta.requiresAuth && typeof to.meta.requiresAuth === 'object') {
    let requiredPermissions = to.meta.requiresAuth;
    isValid = router.app.$permissions().isSysAdmin || router.app.$permissions().isClientAdmin || router.app.$permissions().hasRequiredPermissions(requiredPermissions);
  }

  if (isValid == false) return next({ path: '/login', query: { redirect: to.fullPath } });
  else next();
});

export default router;
