const JWT_TOKEN_NAME = 'jwt';
const USER_INFO_NAME = 'user';

const getBearerToken = function () {
  let jwt = getJwtToken();
  return jwt && jwt.token || null;
}

const getJwtToken = function () {
  let sessionJwtToken = window.sessionStorage.getItem(JWT_TOKEN_NAME)
  return JSON.parse(sessionJwtToken);
}

const getRefreshToken = function () {
  let jwt = getJwtToken();
  return jwt && jwt.refreshToken || null;
}

const getUserToken = function () {
  let sessionUser = window.sessionStorage.getItem(USER_INFO_NAME);
  return JSON.parse(sessionUser);
}

const setToken = function (name, token) {
  window.sessionStorage.setItem(name, JSON.stringify(token));
}

export default {
  keys: {
    'jwt': JWT_TOKEN_NAME,
    'user': USER_INFO_NAME
  },
  getBearerToken: getBearerToken,
  getJwtToken: getJwtToken,
  getRefreshToken: getRefreshToken,
  getUserToken: getUserToken,
  setToken: setToken
}
